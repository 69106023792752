import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { FaSpinner } from 'react-icons/fa'; // Import a spinner icon


const Checkout = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [event, setEvent] = useState(location.state || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phoneNumber: '',
  });
  const [attendees, setAttendees] = useState([{ firstName: '', lastName: '', tShirtSize: 'Small' }]);
  const [total, setTotal] = useState(0);
  const [originalTotal, setOriginalTotal] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track if the form is being submitted
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponMessage, setCouponMessage] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    setIsLoggedIn(!!token);

    const fetchEvent = async () => {
      if (!event) {
        try {
          const response = await fetch(`/api/events/${id}`);
          const data = await response.json();
          setEvent(data);
          setLoading(false);
        } catch (error) {
          setError('Error fetching event details');
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchEvent();
    setCouponApplied(false); // Reset coupon applied state when starting a new order
  }, [id, event]);

  const recalculateTotal = () => {
    if (event) {
      const feePerAttendee = 80 + Math.round(event.price * 0.029); // 80 cents + 2.9% of ticket price
      const subtotal = event.price * attendees.length;
      const totalFees = feePerAttendee * attendees.length; // Calculate total fees based on number of attendees
      let calculatedTotal = subtotal + totalFees;

      // Apply BNTGROUP coupon if applicable
      if (couponApplied && couponCode === 'BNTGROUP' && attendees.length >= 4 && event.price > 3000) {
        const discount = 500 * attendees.length; // $5 discount per attendee
        calculatedTotal -= discount; // Subtract discount from total
      }

      setOriginalTotal(calculatedTotal);
      setTotal(calculatedTotal);
    }
  };

  useEffect(() => {
    recalculateTotal();
  }, [event, attendees]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAttendeeChange = (index, e) => {
    const { name, value } = e.target;
    setAttendees((prevAttendees) => {
      const updatedAttendees = [...prevAttendees];
      updatedAttendees[index][name] = value;
      return updatedAttendees;
    });

    if (attendees.length < 4 && couponApplied) {
      setCouponApplied(false);
      setCouponMessage('');
      setCouponCode('');  // Optional: Reset the coupon code input field
    }

    recalculateTotal(); // Recalculate total on attendee change
  };

  const addAttendee = () => {
    setAttendees([...attendees, { firstName: '', lastName: '', tShirtSize: 'Small' }]);

    if (attendees.length + 1 >= 4 && couponCode === 'BNTGROUP' && !couponApplied) {
      applyCoupon();
    } else {
      recalculateTotal(); // Recalculate total after adding attendee
    }
  };

  const removeAttendee = (index) => {
    setAttendees(attendees.filter((_, i) => i !== index));

    if (attendees.length - 1 < 4 && couponApplied) {
      setCouponApplied(false);
      setCouponMessage('');
      setCouponCode('');  // Optional: Reset the coupon code input field
    }

    recalculateTotal(); // Recalculate total after removing attendee
  };

  const applyCoupon = () => {
    let updatedTotal = originalTotal;

    if (couponApplied) {
      setErrorMessage('Coupon already applied.');
      return;
    }

    if (couponCode === 'BNTSPONSOR') {
      updatedTotal = 0;
      setCouponApplied(true);
      setCouponMessage('Coupon applied successfully! Total is now $0.');
      setErrorMessage('');
    } else if (couponCode === 'BNTGROUP' && attendees.length >= 4 && event.price > 3000) {
      const discount = 500 * attendees.length;
      updatedTotal -= discount;
      setCouponApplied(true);
      setCouponMessage(`Coupon applied successfully! Discount Applied.`);
      setErrorMessage('');
    } else {
      setErrorMessage('Invalid coupon code or conditions not met.');
      setCouponMessage('');
      setCouponApplied(false);
      setTotal(originalTotal); // Reset total to original if coupon is invalid or removed
    }

    setTotal(updatedTotal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.email ||
      formData.email !== formData.confirmEmail ||
      !formData.phoneNumber ||
      !agreedToTerms
    ) {
      setErrorMessage('Please fill out all required fields and ensure emails match.');
      if (!agreedToTerms) {
        setErrorMessage('You must agree to the terms and conditions.');
      }
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      let confirmedPaymentIntent = null;
  
      if (total > 0) {
        const paymentIntentResponse = await fetch('/api/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount: total, eventId: event.id, attendeesCount: attendees.length, couponCode }),
        });
  
        if (!paymentIntentResponse.ok) {
          const errorData = await paymentIntentResponse.json();
          throw new Error(errorData.message || 'Failed to create payment intent');
        }
  
        const paymentIntent = await paymentIntentResponse.json();
  
        if (!paymentIntent.clientSecret) {
          throw new Error('Payment intent not created successfully');
        }
  
        const cardElement = elements.getElement(CardElement);
  
        if (paymentIntent.requiresAction) {
          // Handle 3D Secure authentication
          const { error, paymentIntent: updatedIntent } = await stripe.handleCardAction(
            paymentIntent.clientSecret
          );
          if (error) {
            throw new Error('Authentication failed. Please try again.');
          }
          confirmedPaymentIntent = updatedIntent;
        } else {
          // Proceed with normal confirmation
          const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: `${formData.firstName} ${formData.lastName}`,
                email: formData.email,
                phone: formData.phoneNumber,
              },
            },
          });
  
          if (result.error) {
            throw new Error(result.error.message);
          }
  
          confirmedPaymentIntent = result.paymentIntent;
        }
  
        if (confirmedPaymentIntent.status === 'requires_action') {
          // The payment requires additional actions
          const { error, paymentIntent } = await stripe.handleCardAction(
            confirmedPaymentIntent.client_secret
          );
          if (error) {
            throw new Error('Additional authentication failed. Please try again.');
          }
          confirmedPaymentIntent = paymentIntent;
        }
  
        if (confirmedPaymentIntent.status !== 'succeeded') {
          throw new Error('Payment was not successful. Please try again.');
        }
      }
  
      const token = localStorage.getItem('userToken');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }
  
      const orders = [];
      for (const attendee of attendees) {
        let finalTotal = originalTotal;
        if (couponApplied) {
          if (couponCode === 'BNTSPONSOR') {
            finalTotal = 0;
          } else if (couponCode === 'BNTGROUP') {
            const discount = 500 * attendees.length;
            finalTotal = originalTotal - discount;
          }
        }
  
        const order = {
          orderNumber: confirmedPaymentIntent ? confirmedPaymentIntent.id : 'FREE_ORDER',
          event,
          total: finalTotal,
          formData: {
            ...formData,
            firstName: attendee.firstName,
            lastName: attendee.lastName,
            tShirtSize: attendee.tShirtSize,
          },
          couponApplied: couponApplied ? couponCode : null,
        };
  
        const saveOrderResponse = await fetch('/api/orders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(order),
        });
  
        if (!saveOrderResponse.ok) {
          const errorData = await saveOrderResponse.json();
          throw new Error(errorData.message || 'Failed to save order');
        }
  
        orders.push(order);
      }
  
      navigate('/confirmation', { state: { orders } });
      setErrorMessage('');
  
      // Clear form data and reset state
      setFormData({ firstName: '', lastName: '', email: '', confirmEmail: '', phoneNumber: '' });
      setAttendees([{ firstName: '', lastName: '', tShirtSize: 'Small' }]);
      setCouponCode('');
      setCouponApplied(false);
      setAgreedToTerms(false);
  
    } catch (error) {
      setErrorMessage(error.message || 'An unexpected error occurred');
      console.error('Checkout error:', error);
      // Log additional details if available
      if (error.payment_intent) {
        console.error('Payment Intent ID:', error.payment_intent.id);
        console.error('Payment Intent Status:', error.payment_intent.status);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDate = (dateString) => {
    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
  
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const formattedDate = `${months[parseInt(month, 10) - 1]} ${parseInt(day, 10)}, ${year}`;
    return formattedDate;
  };

  const formatTime = (timeString) => {
    if (!timeString) return '';
    const [hour, minute] = timeString.split(':');
    const hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? 'PM' : 'AM';
    const formattedHour = hourInt % 12 || 12;
    return `${formattedHour.toString().padStart(2, '0')}:${minute} ${period}`;
  };

  if (loading) {
    return <p className="text-center mt-10 text-xl">Loading...</p>;
  }

  if (error) {
    return <p className="text-center mt-10 text-xl text-red-500">{error}</p>;
  }

  if (!isLoggedIn) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-[#D1D1C1] to-[#073B4C] flex items-center justify-center py-8 px-4">
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4 text-[#073B4C] text-center">Login Required</h2>
          <p className="text-red-500 text-center mb-4">You need to be logged in to proceed with the checkout. Repeat Buyer, please log in.</p>
          <div className="flex flex-col space-y-4">
            <button
              className="w-full bg-[#073B4C] text-white py-2 px-4 rounded-md hover:bg-[#065A60] transition duration-200"
              onClick={() => navigate('/login')}
            >
              Login Here
            </button>
            <p className="text-center text-gray-700">First time buyer?</p>
            <button
              className="w-full bg-[#F9C74F] text-white py-2 px-4 rounded-md hover:bg-[#F9844A] transition duration-200"
              onClick={() => navigate('/register')}
            >
              Register Here
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#D1D1C1] to-[#073B4C] flex justify-center items-center py-8 px-4">
      <div className="container mx-auto max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4 text-[#073B4C]">Billing Information</h2>
            <div className="mb-4 p-2 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
              <p>
                Note: Each individual attending the event must have their own ticket. Please ensure that the name entered for each ticket is the name of the person who will be attending. Other information can differ, but the names at the bottom must be for the attendee/s.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Confirm Email</label>
                <input
                  type="email"
                  name="confirmEmail"
                  value={formData.confirmEmail}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Coupon Code</label>
                <input
                  type="text"
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  placeholder="Enter coupon to apply"
                />
                <button
                  type="button"
                  onClick={applyCoupon}
                  className="mt-2 p-2 bg-[#073B4C] text-white rounded-md hover:bg-[#065A60]"
                >
                  Apply Coupon
                </button>
                {couponMessage && (
                  <div className="mt-2 p-2 bg-green-100 text-green-800 border border-green-300 rounded">
                    {couponMessage}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Card Details</label>
                <CardElement
                  className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#32325d',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#fa755a',
                      },
                    },
                  }}
                  disabled={couponApplied && total === 0}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">
                  <input
                    type="checkbox"
                    className="mr-2 leading-tight"
                    checked={agreedToTerms}
                    onChange={() => setAgreedToTerms(!agreedToTerms)}
                  />
                  I agree to the total amount owing on this page and that promo codes must be entered before ticket selection and cannot be applied after purchase. I understand my card will be charged by selecting Place Order at the bottom of this page. I also agree to the event's terms and conditions, including no refunds and the event organizer's right to change event details. To ensure you receive a shirt size of your preference, please arrive to the run early, rfp London, cannot guarantee shirt size availability. We will distribute the shirt on a first come, first serve basis. 
                  Implied photography consent.
                  This is a public event, in a public space, and there is no guarantee for privacy when it comes to photography and social media posts.
                </label>
              </div>
              {errorMessage && (
                <div className="mb-4 p-2 bg-red-100 text-red-800 border border-red-300 rounded">
                  {errorMessage}
                </div>
              )}
              <div className="mb-4">
                <h3 className="text-lg font-semibold">Attendees</h3>
                {attendees.map((attendee, index) => (
                  <div key={index} className="mb-2">
                    <div className="flex">
                      <input
                        type="text"
                        name="firstName"
                        placeholder={`Attendee ${index + 1} First Name`}
                        value={attendee.firstName}
                        onChange={(e) => handleAttendeeChange(index, e)}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-1/2 mr-2"
                        required
                      />
                      <input
                        type="text"
                        name="lastName"
                        placeholder={`Attendee ${index + 1} Last Name`}
                        value={attendee.lastName}
                        onChange={(e) => handleAttendeeChange(index, e)}
                        className="mt-1 p-2 border border-gray-300 rounded-md w-1/2"
                        required
                      />
                    </div>
                    <label className="block text-gray-700 mt-2">T-Shirt Size</label>
                    <select
                      name="tShirtSize"
                      value={attendee.tShirtSize}
                      onChange={(e) => handleAttendeeChange(index, e)}
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      required
                    >
                      <option value="Small">Small</option>
                      <option value="Medium">Medium</option>
                      <option value="Large">Large</option>
                      <option value="X-Large">X-Large</option>
                    </select>
                    {attendees.length > 1 && (
                      <button
                        type="button"
                        onClick={() => removeAttendee(index)}
                        className="mt-2 p-2 bg-[#073B4C] text-white rounded-md hover:bg-[#065A60]"
                      >
                        Remove Attendee
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addAttendee}
                  className="mt-2 p-2 bg-[#073B4C] text-white rounded-md hover:bg-[#065A60]"
                >
                  Add Attendee
                </button>
              </div>
              <button
                type="submit"
                className="mt-4 w-full bg-[#073B4C] text-white p-2 rounded-md hover:bg-[#065A60]"
                disabled={!stripe || isSubmitting} // Disable the button while submitting
              >
                {isSubmitting ? (
                  <span className="flex items-center justify-center">
                    <FaSpinner className="animate-spin mr-2" />
                    Processing...
                  </span>
                ) : (
                  'Place Order'
                )}
              </button>
            </form>
          </div>
          <div className="p-6 bg-gray-50 border-l border-gray-200">
            <h2 className="text-2xl font-bold mb-4 text-[#073B4C]">Order Summary</h2>
            <div className="mb-4">
              <h3 className="text-xl font-semibold">{event.title}</h3>
              <p className="text-gray-600">{formatDate(event.date)}</p>
              <p className="text-gray-600">{event.location}</p>
              <p className="text-gray-600">{`Start Time: ${formatTime(event.start_time)}, End Time: ${formatTime(event.end_time)}`}</p>
            </div>
            <div className="border-t border-gray-200 pt-4">
              <div className="flex justify-between mb-2">
                <span>Subtotal</span>
                <span>${((event.price * attendees.length) / 100).toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Fees</span>
                <span>${((80 * attendees.length + Math.round(event.price * attendees.length * 0.029)) / 100).toFixed(2)}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Tax</span>
                <span>$0.00</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Delivery</span>
                <span>$0.00</span>
              </div>
              {couponApplied && attendees.length >= 4 && (
                <div className="flex justify-between mb-2 text-green-600">
                  <span>Coupon Applied ({couponCode})</span>
                  <span>-${((500 * attendees.length) / 100).toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between mt-4 font-bold">
                <span>Total</span>
                <span>${(total / 100).toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
