import React from 'react';

const Home = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#F3F4F6] to-[#E5E7EB] flex items-center justify-center">
      <main className="container mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold mb-8 text-[#073B4C]">Tickets are Closed</h1>
        
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8 max-w-md mx-auto">
          <p className="text-xl text-gray-700 mb-4">
            Thanks for purchasing tickets through Banatcom Payment Solutions!
          </p>
          <div className="bg-[#073B4C] text-white p-4 rounded-md">
            <span className="font-bold text-2xl">$500</span>
            <p className="mt-2">
              in extra savings for donations just by using this platform!
            </p>
          </div>
        </div>
        
        <a 
          href="https://www.eventbrite.ca/e/run-for-palestine-london-ontario-2024-tickets-949892252297?aff=oddtdtcreator#:~:text=The%20event%20will%20take%20place,trail%20accommodates%20wheelchairs%20and%20strollers."
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block bg-[#073B4C] text-white font-bold py-3 px-6 rounded-full text-lg hover:bg-[#05293A] transition-colors duration-300 mt-4"
        >
          Last Minute Tickets Click Here
        </a>
      </main>
    </div>
  );
};

export default Home;