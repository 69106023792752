import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import Modal from './Modal';

function IdleTimerContainer({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOnIdle = () => {
    // Logout user after 30 minutes of inactivity
    localStorage.removeItem('userToken');
    navigate('/login');
    alert('You have been logged out due to inactivity.');
  };

  useIdleTimer({
    timeout: 30 * 60 * 1000, // 30 minutes in milliseconds
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {children}
      <Modal isOpen={isModalOpen} onClose={toggleModal}>
        <div className="p-4">
          <p>This is a modal content with sufficient margins.</p>
        </div>
      </Modal>
    </>
  );
}

export default IdleTimerContainer;